import { CameraIcon } from "@heroicons/react/16/solid";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenAtom } from "../../lib/atom";
import useLogin from "../../components/useLogin";

export default function SearchBar({ state, searchParams, setSearchParams }) {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useRecoilValue(tokenAtom);
  const { handleLogin } = useLogin();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty, dirtyFields },
  } = useForm();

  const onSubmit = (data) => {
    if (!data.page) setSearchParams(Object.assign(data, { page: 1 }));
    else setSearchParams(data);
  };

  useEffect(() => {
    if (!!searchParams.get("keyword")) {
      setValue("keyword", searchParams.get("keyword"));
    }
    if (!!state && state.isbn) {
      setValue("keyword", state.isbn);
      onSubmit({ keyword: state.isbn });
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full lg:flex">
        <div className="flex space-x-2 w-full">
          <div className="flex justify-center items-center relative w-full ">
            <input
              {...register("keyword")}
              className="bg-white w-full border border-slate-200 rounded-md p-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
              placeholder="검색어를 입력해주세요."
            ></input>
            <CameraIcon
              onClick={() => {
                if (!!token) {
                  navigate(`/camera`, {
                    state: { location: location.pathname },
                  });
                } else handleLogin();
              }}
              className="h-9 p-1 text-gray-400 rounded-md right-2 hover:text-sky-500 absolute"
            />
          </div>

          <input
            type="submit"
            value="검색"
            className="bg-sky-500 rounded-md text-white font-bold px-3 cursor-pointer w-28"
          />
        </div>

        {/* <div
          role="button"
          onClick={() => navigate("/search/advanced")}
          className="mt-4 xl:mt-0 xl:ml-2 w-28 text-center border border-sky-500 rounded-md text-sky-500 font-bold p-3 no-wrap cursor-pointer hover:bg-sky-500/10"
        >
          상세검색
        </div> */}
      </form>
    </>
  );
}

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import api from "../../lib/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

export default function CartItem() {
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // /api/userCartBook/info/{접근id} (도서정보)
  // /api/userCartBook/remove/{접근id} (도서삭제)
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`CartBookItem`, param.id],
    queryFn: () =>
      axios
        .post(
          `/api/userCartBook/info/${param.id}`,
          {},
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => res.data.data)
        .catch((err) => console.log(err)),
  });

  const td_title =
    "py-3 pr-4 md:p-3 text-right text-sm lg:w-1/4 text-gray-700 font-bold";
  const td_body = "lg:text-lg";
  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto m-4">
        <div className="mt-16 text-sm mb-1">No. {param.id}</div>
        {isLoading ? (
          "Loading..."
        ) : error ? (
          error.message
        ) : data ? (
          <>
            <div className="mb-4 w-full bg-gray-100 p-2 md:p-8 rounded-md lg:flex">
              {/* <div className="bg-white w-[200px] h-[240px] lg:mr-8 mx-auto mb-8 flex items-center justify-center text-gray-300">
            Cover Image
          </div> */}
              <div className="w-full">
                <table className="w-full">
                  <thead></thead>
                  <tbody className="*:border-b *:border-dashed *:border-gray-200 *:bg-white ">
                    <tr>
                      <td className={td_title}>도서ID</td>
                      <td className={td_body}>{data.bookId}</td>
                    </tr>

                    <tr>
                      <td className={td_title}>도서명</td>
                      <td className={td_body}>{data.title}</td>
                    </tr>
                    {/* <tr>
                      <td className={td_title}>제작일</td>
                      <td className={td_body}>{data.prodDate}</td>
                    </tr> */}

                    <tr>
                      <td className={td_title}>학교급</td>
                      <td className={td_body}>{data.grade}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>출판사</td>
                      <td className={td_body}>{data.publisher}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>저자</td>
                      <td className={td_body}>{data.creator}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>커리큘럼</td>
                      <td className={td_body}>{data.curriculum}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>국검인</td>
                      <td className={td_body}>{data.permission}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>교과용도</td>
                      <td className={td_body}>{data.purpose}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>요청종류</td>
                      <td className={td_body}>
                        {data.prodType?.split(" ").join(" / ")}
                      </td>
                    </tr>
                    <tr>
                      <td className={td_title}>발행일</td>
                      <td className={td_body}>{data.pubDate}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>ISBN</td>
                      <td className={td_body}>{data.isbn}</td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
            <div className="flex mb-12">
              {!!location?.state?.location && (
                <button
                  className="flex hover:bg-gray-200 p-2 text-gray-700  dark:hover:bg-gray-700 dark:hover:text-gray-200"
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon className="mt-[0.2rem] h-4 items-center justify-center " />
                  <span className="text-sm items-center justify-center font-semibold">
                    뒤로 가기
                  </span>
                </button>
              )}
            </div>
          </>
        ) : (
          <div>존재하지 않는 자료입니다.</div>
        )}
      </div>
    </>
  );
}

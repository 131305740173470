import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";

export default function ParamPagination({
  data,
  setPage,
  page,
  setSearchParams,
  searchParams,
}) {
  const currentPage = useRef();
  return (
    <nav id="page" className="mt-8 flex justify-center">
      {data?.page !== 1 && data?.page > 1 && (
        <button
          className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
          title={"처음 페이지로"}
          onClick={() => {
            searchParams.set("page", 1);
            setSearchParams(searchParams);
            setPage(1);
          }}
        >
          <ChevronDoubleLeftIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
        </button>
      )}

      {data?.page > 1 && (
        <button
          className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
          title={"이전 페이지로"}
          onClick={() => {
            searchParams.set("page", data.page - 1);
            setSearchParams(searchParams);
            setPage(data.page - 1);
          }}
        >
          <ChevronLeftIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
        </button>
      )}

      <input
        id={data?.page}
        className="bg-stone-100 text-stone-500 mx-1 items-center justify-center py-0 focus:outline-none text-center w-24 cursor-pointer"
        defaultValue={page}
        ref={currentPage}
        inputmode="numeric"
        onKeyDown={(e) => {
          if (e.keyCode === 13 && Number(currentPage.current.value)) {
            if (Number(currentPage.current.value) > data?.totalPage) {
              currentPage.current.value = data.totalPage;
            } else if (currentPage.current.value < 1) {
              currentPage.current.value = 1;
            }
            searchParams.set("page", Number(currentPage.current.value));
            setSearchParams(searchParams);
            setPage(Number(currentPage.current.value));
          }
        }}
      />

      {data?.page < data?.totalPage && (
        <button
          className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
          title={"다음 페이지로"}
          onClick={() => {
            searchParams.set("page", data.page + 1);
            setSearchParams(searchParams);
            setPage(data.page + 1);
          }}
        >
          <ChevronRightIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
        </button>
      )}
      {data?.page !== data?.totalPage && data?.page < data?.totalPage && (
        <button
          className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
          // title={data.totalPage + " 페이지로"}
          title={"마지막 페이지로"}
          onClick={() => {
            searchParams.set("page", data.totalPage);
            setSearchParams(searchParams);
            setPage(data.totalPage);
          }}
        >
          <ChevronDoubleRightIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
        </button>
      )}
    </nav>
  );
}

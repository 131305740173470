import { useRecoilValue } from "recoil";
import { tokenAtom } from "../lib/atom";
import { useContext, useEffect } from "react";

import { AuthContext } from "../lib/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import useLogin from "./useLogin";

export default function LoginButton() {
  const token = useRecoilValue(tokenAtom);
  const { handleLogin } = useLogin();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!!location?.state?.from) {
      if (!!token) navigate(location.state.from);
    } else return;
  }, [token]);

  return (
    <>
      {!token ? (
        <div
          className="border border-blue-500 bg-blue-500 sm:rounded-full py-4 sm:py-2 sm:my-2 sm:items-center hover:bg-blue-600 sm:text-center sm:flex px-6   cursor-pointer text-white font-bold"
          onClick={handleLogin}
        >
          로그인
        </div>
      ) : (
        <div
          className="sm:border sm:border-red-400 text-red-400 sm:rounded-full py-4 sm:py-2 sm:my-2 sm:items-center sm:text-center sm:flex px-6  sm:hover:bg-red-100 hover:bg-red-100 cursor-pointer font-bold"
          onClick={() => {
            auth.logout();
            navigate("/");
          }}
        >
          로그아웃
        </div>
      )}
    </>
  );
}

import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";

export default function ListParamPagination({
  data,
  setPage,
  setSearchParams,
  searchParams,
}) {
  const pageList = data?.pageList?.map((page) => {
    if (page === data.page) {
      return (
        <button
          key={page}
          id={page}
          className="text-stone-400  dark:hover:bg-gray-800 hover:bg-gray-50 relative inline-flex items-center justify-center w-6 sm:px-4 px-3 lg:w-12 sm:py-2 text-xs font-bold  border-b-2 border-b-stone-400 cursor-pointer "
        >
          {page}
        </button>
      );
    } else {
      return (
        <button
          key={page}
          id={page}
          className="text-stone-500 dark:hover:bg-gray-800 dark:hover:text-stone-100 hover:bg-gray-50 relative inline-flex items-center justify-center w-6 sm:px-4 px-3 lg:w-12 sm:py-2 text-xs border-b-2 border-transparent cursor-pointer"
          title={page + " 페이지로"}
          onClick={() => {
            searchParams.set("page", page);
            setSearchParams(searchParams);
            setPage(page);
          }}
        >
          {page}
        </button>
      );
    }
  });

  return (
    <nav id="page" className="mt-8 flex justify-center">
      {data?.prev && (
        <div>
          <button
            className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
            title={"처음 페이지로"}
            onClick={() => {
              searchParams.set("page", 1);
              setSearchParams(searchParams);
              setPage(1);
            }}
          >
            <ChevronDoubleLeftIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
          </button>
        </div>
      )}

      {data?.start > 1 && (
        <div>
          <button
            className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
            title={Number(data.start) - 1 + " 페이지로"}
            onClick={() => {
              searchParams.set("page", data.start - 1);
              setSearchParams(searchParams);
              setPage(data.start - 1);
            }}
          >
            <ChevronLeftIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
          </button>
        </div>
      )}

      {pageList}

      {data?.page < data?.totalPage && data?.end < data?.totalPage && (
        <div>
          <button
            className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
            title={Number(data.end) + 1 + " 페이지로"}
            onClick={() => {
              searchParams.set("page", data.end + 1);
              setSearchParams(searchParams);
              setPage(data.end + 1);
            }}
          >
            <ChevronRightIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
          </button>
        </div>
      )}
      {data?.next && (
        <div>
          <button
            className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
            // title={data.totalPage + " 페이지로"}
            title={"마지막 페이지로"}
            onClick={() => {
              searchParams.set("page", data.totalPage);
              setSearchParams(searchParams);
              setPage(data.totalPage);
            }}
          >
            <ChevronDoubleRightIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
          </button>
        </div>
      )}
    </nav>
  );
}

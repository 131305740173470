import qs from "query-string";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import axios from "axios";
import api from "../../lib/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ParamPagination from "../../components/ParamPagination";
import SearchBar from "./SearchBar";
import { useRecoilValue } from "recoil";
import { tokenAtom } from "../../lib/atom";
import useLogin from "../../components/useLogin";

export default function SearchMain() {
  // address parameter
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [keyword, setKeyword] = useState();
  const [sort, setSort] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const token = useRecoilValue(tokenAtom);
  const { handleLogin, setPopup } = useLogin();

  useEffect(() => {
    if (searchParams.size !== 0) {
      if (!!token) {
        setPopup(null);
        setPage(Number(searchParams.get("page")));
        setKeyword(searchParams.get("keyword"));
      } else {
        handleLogin();
      }
    }
  }, [searchParams, token]);

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`BookKeyList`, { page, size, keyword, sort }],
    queryFn: () =>
      axios
        .post(
          `/api/book/search`,
          qs.stringify({
            size,
            sort,
            keyword: searchParams.get("keyword"),
            page: searchParams.get("page"),
          }),
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          return res.data.data;
        })
        .catch(function (error) {
          if (error.response) {
            // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
            // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
            // node.js에서는 http.ClientRequest 인스턴스입니다.
            console.log(error.request);
          } else {
            // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
            console.log("Error", error.message);
          }
          console.log(error.config);
        }),
    enabled: !!keyword,
    staleTime: 1000 * 60 * 3,
    refetchOnMount: false,
  });

  const tdhead = "text-xs w-16 pb-1 px-2 text-right text-gray-800";
  const tdbody = "text-sm pb-1 px-2";

  const bookList = data?.items?.map((item) => {
    const type = item.prodType?.split(" ").map((item, index) => (
      <span
        key={"type" + index}
        className={
          item === "점자"
            ? `block ml-1 py-1 px-2 text-sm font-normal rounded-md bg-sky-700/20 `
            : item === "음성"
            ? `block ml-1 py-1 px-2 text-sm font-normal rounded-md bg-green-700/20 `
            : `block ml-1 py-1 px-2 text-sm font-normal rounded-md bg-yellow-700/20 `
        }
      >
        {item}
      </span>
    ));
    if (item.page > data.totalPage)
      return <div>존재하지 않는 페이지입니다.</div>;
    else
      return (
        <table
          key={item.id}
          className="bg-gray-100 hover:bg-sky-50 mb-2 cursor-pointer w-full rounded-md "
          onClick={() =>
            navigate(`/item/${item.id}`, {
              state: { page, sort, keyword, size, location: location.pathname },
            })
          }
        >
          <thead>
            <tr>
              <td className="text-sm w-16 p-1 text-right items-center">
                <span className="text-sm px-2 rounded-md bg-gray-500 text-white">
                  {item.grade}
                </span>
              </td>
              <td className="p-1 px-2 font-bold flex items-center">
                <span className="mr-auto">{item.title}</span>
                {type}
              </td>
            </tr>
          </thead>
          <tbody className="text-sm">
            <tr>
              <td className={tdhead}>발행사</td>
              <td className={tdbody}>{item.publisher}</td>
            </tr>
            <tr>
              <td className={tdhead}>저자</td>
              <td className={tdbody}>{item.creator}</td>
            </tr>
            <tr>
              <td className={tdhead}>발행일</td>
              <td className={tdbody}>{item.pubDate}</td>
            </tr>
            <tr>
              <td className={tdhead}>ISBN</td>
              <td className={tdbody}>{item.isbn}</td>
            </tr>
          </tbody>
        </table>
      );
  });

  const bookTableList = data?.items?.map((item) => {
    const type = item.prodType?.split(" ").map((item, index) => (
      <span
        key={"type" + index}
        className={
          item === "점자"
            ? `block ml-1 py-1 px-2 text-sm font-normal rounded-md bg-sky-700/20 `
            : item === "음성"
            ? `block ml-1 py-1 px-2 text-sm font-normal rounded-md bg-green-700/20 `
            : `block ml-1 py-1 px-2 text-sm font-normal rounded-md bg-yellow-700/20 `
        }
      >
        {item}
      </span>
    ));
    if (item.page > data.totalPage)
      return <div>존재하지 않는 페이지입니다.</div>;
    else
      return (
        <table
          key={item.id}
          className="bg-gray-100 hover:bg-sky-50 my-2 cursor-pointer w-full rounded-md "
          onClick={() =>
            navigate(`/item/${item.id}`, {
              state: { page, sort, keyword, size, location: location.pathname },
            })
          }
        >
          <thead>
            <tr>
              <td className="text-sm w-16 p-1 text-right items-center">
                <span className="text-sm px-2 rounded-md bg-gray-500 text-white">
                  {item.grade}
                </span>
              </td>
              <td className="p-1 px-2 font-bold flex items-center">
                <span className="mr-auto">{item.title}</span>
                {type}
              </td>
            </tr>
          </thead>
          <tbody className="text-sm">
            <tr>
              <td className={tdhead}>발행사</td>
              <td className={tdbody}>{item.publisher}</td>
            </tr>
            <tr>
              <td className={tdhead}>저자</td>
              <td className={tdbody}>{item.creator}</td>
            </tr>
            <tr>
              <td className={tdhead}>발행일</td>
              <td className={tdbody}>{item.pubDate}</td>
            </tr>
            <tr>
              <td className={tdhead}>ISBN</td>
              <td className={tdbody}>{item.isbn}</td>
            </tr>
          </tbody>
        </table>
      );
  });

  return (
    <>
      <div
        className={
          !!data
            ? `xl:flex xl:space-x-2 justify-center w-full md:w-2/3 lg:w-1/3 p-4 items-center mx-auto mt-16 `
            : `xl:flex xl:space-x-2 justify-center w-full md:w-2/3 lg:w-1/3 p-4 items-center mx-auto absolute top-[50vh] left-[50vw] z-50 -translate-x-1/2 -translate-y-1/2`
        }
      >
        <SearchBar
          setKeyword={setKeyword}
          setPage={setPage}
          state={location.state}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </div>
      <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
        {isLoading ? (
          <div className="text-center mt-16">Loading...</div>
        ) : data === undefined ? (
          <div></div>
        ) : error ? (
          <div>{error.message}</div>
        ) : (
          <>
            <div className="text-sm mt-8">
              '{keyword}' 검색 결과 {data?.totalCount} 건
            </div>
            <div className="overflow-x-auto">
              <div>{bookList}</div>
            </div>
            <ParamPagination
              data={data}
              page={page}
              setPage={setPage}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
          </>
        )}
      </div>
    </>
  );
}

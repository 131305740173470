import qs from "query-string";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import api from "../../lib/api";

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { searchWord } from "../../lib/print_text";
import AdvancedSearchBar from "./AdvancedSearchBar";
import useLogin from "../../components/useLogin";
import { useRecoilValue } from "recoil";
import { tokenAtom } from "../../lib/atom";
import ListParamPagination from "../../components/ListParamPagination";

export default function AdvancedSearchMain() {
  // address parameter
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [size, setSize] = useState(Number(searchParams.get("size")) || 10);
  const [keyword, setKeyword] = useState({});
  const [sort, setSort] = useState(Number(searchParams.get("sort")) || 0);
  const navigate = useNavigate();
  const searchSize = useRef();
  const sortRef = useRef();
  const location = useLocation();
  const token = useRecoilValue(tokenAtom);
  const { handleLogin, setPopup } = useLogin();

  useEffect(() => {
    console.log("1");
    if (!token) handleLogin();
    else {
      setPopup(null);
      let object = {};
      for (const [key, value] of searchParams.entries()) {
        object[key] = value;
      }
      setKeyword(object);
    }
  }, [searchParams, token]);

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`BookList`, { page, size, keyword, sort }],
    queryFn: () =>
      axios
        .post(
          `/api/book/list`,
          qs.stringify({ page, size }) +
            "&" +
            qs.stringify(keyword) +
            "&" +
            qs.stringify({ sort }),
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((error) => {
          if (error.response) {
            // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
            // console.log(error.response.data);
            //window.alert(error.response.status);
            if (error.response.status === 403) {
              handleLogin();
            }
            // console.log(error.response.headers);
          } else if (error.request) {
            // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
            // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
            // node.js에서는 http.ClientRequest 인스턴스입니다.
            console.log(error.request);
          } else {
            // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
            console.log("Error", error.message);
          }
          console.log(error.config);
        }),

    enabled: !!token,
    // staleTime: 1000 * 60 * 3,
    refetchOnMount: false,
  });

  const bookList = data?.items?.map((item) => {
    return (
      <tr
        key={item.id}
        onClick={() =>
          navigate(`/item/${item.id}`, {
            state: { page, sort, keyword, size, location: location.pathname },
          })
        }
        className=" text-center text-sm border-b border-gray-300 border-dashed odd:bg-gray-100 hover:bg-sky-50 cursor-pointer last-of-type:border-solid *:p-2"
      >
        <td>{item.prodDate}</td>
        <td>{item.bookId}</td>
        <td>{item.grade}</td>
        <td>{item.publisher}</td>
        <td>{item.creator}</td>
        <td>{item.title}</td>
        <td>{item.curriculum}</td>
        <td>{item.permission}</td>
        <td>{item.purpose}</td>
        <td>{item.prodType?.split(" ").join(" / ")}</td>
        <td className="text-xs">{item.pubDate}</td>
        <td className="text-xs">{item.isbn}</td>
      </tr>
    );
  });

  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
        <div className="font-bold text-2xl my-8">통합 자료 검색</div>
        <AdvancedSearchBar
          state={location.state}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          token={token}
        />
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error.message}</div>
        ) : (
          <>
            {!!data && (
              <>
                <div className="md:flex text-xs items-center mb-1">
                  <div>
                    {/* <span className="font-bold">{searchWord(keyword)}</span>{" "} */}
                    검색 결과: {data?.totalCount} 건
                  </div>
                  <div className="md:ml-auto flex items-center space-x-2 my-1">
                    <div>정렬</div>
                    <select
                      id="sort"
                      ref={sortRef}
                      value={sort}
                      onChange={() => {
                        searchParams.set("sort", sortRef.current.value);
                        setSearchParams(searchParams);
                        setSort(sortRef.current.value);
                      }}
                      className="h-6 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-1 border border-[#e5e7eb]"
                    >
                      <option name="sort" value={0}>
                        제작일 최신순
                      </option>
                      <option name="sort" value={1}>
                        도서ID 최신순
                      </option>
                    </select>
                    <div>페이지당 출력수</div>
                    <select
                      id="size"
                      value={size}
                      ref={searchSize}
                      onChange={() => {
                        searchParams.set("size", searchSize.current.value);
                        setSearchParams(searchParams);
                        setSize(searchSize.current.value);
                      }}
                      className="h-6 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-1 border border-[#e5e7eb]"
                    >
                      <option name="size" value={10}>
                        10건
                      </option>
                      <option name="size" value={20}>
                        20건
                      </option>
                      <option name="size" value={50}>
                        50건
                      </option>
                      <option name="size" value={100}>
                        100건
                      </option>
                    </select>
                  </div>
                </div>

                <div className="overflow-x-auto">
                  <table className="mx-auto w-full table-auto">
                    <thead>
                      <tr className="bg-gray-200 text-center *:bg-gray-200 *:py-2 *:text-sm *:font-bold ">
                        <td>제작일</td>
                        <td>도서 ID</td>
                        <td>학교급</td>
                        <td>발행사</td>
                        <td>저자</td>
                        <td>도서명</td>
                        <td>교육과정</td>
                        <td>국검인</td>
                        <td>교과용도</td>
                        <td>제작종류</td>
                        <td>발행일</td>
                        <td>isbn</td>
                      </tr>
                    </thead>
                    <tbody>{bookList}</tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
                <ListParamPagination
                  data={data}
                  setPage={setPage}
                  setSearchParams={setSearchParams}
                  searchParams={searchParams}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

import qs from "query-string";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../lib/api";

import { printTime } from "../lib/print_text";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { PiFileMagnifyingGlassFill, PiSpeakerHighFill } from "react-icons/pi";
import { TbBraille } from "react-icons/tb";

export default function BookItem() {
  const param = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`BookItem`, param.id],
    queryFn: () =>
      axios
        .post(
          `/api/book/info/${param.id}`,
          {},
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => console.log(err)),
  });
  // useEffect(() => console.log(location.state), []);

  const td_title =
    "py-3 pr-4 md:p-3 text-right text-sm lg:w-1/4 text-gray-700 font-bold";
  const td_body = "lg:text-lg";

  const addCart = (type) => {
    axios
      .post(
        `/api/userCartBook/add`,
        qs.stringify({ bookId: data.bookId, prodType: type }),
        {
          headers: {
            Authorization: "bearer " + api.getToken(),
          },
        }
      )
      .then((res) => window.alert("장바구니에 추가되었습니다."))
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto m-4">
        <div className="mt-16 text-sm mb-1">No. {param.id}</div>
        {isLoading ? (
          "Loading..."
        ) : error ? (
          error.message
        ) : data ? (
          <>
            <div className="mb-4 w-full bg-gray-100 p-2 md:p-8 rounded-md lg:flex">
              {/* <div className="bg-white w-[200px] h-[240px] lg:mr-8 mx-auto mb-8 flex items-center justify-center text-gray-300">
                Cover Image
              </div> */}
              <div className="w-full">
                <table className="w-full">
                  <thead></thead>
                  <tbody className="*:border-b *:border-dashed *:border-gray-200 *:bg-white ">
                    <tr>
                      <td className={td_title}>도서ID</td>
                      <td className={td_body}>{data.bookId}</td>
                    </tr>

                    <tr>
                      <td className={td_title}>도서명</td>
                      <td className={td_body}>{data.title}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>제작일</td>
                      <td className={td_body}>{data.prodDate}</td>
                    </tr>

                    <tr>
                      <td className={td_title}>학교급</td>
                      <td className={td_body}>{data.grade}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>출판사</td>
                      <td className={td_body}>{data.publisher}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>저자</td>
                      <td className={td_body}>{data.creator}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>커리큘럼</td>
                      <td className={td_body}>{data.curriculum}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>국검인</td>
                      <td className={td_body}>{data.permission}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>교과용도</td>
                      <td className={td_body}>{data.purpose}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>제작종류</td>
                      <td className={td_body}>
                        {data.prodType?.split(" ").join(" / ")}
                      </td>
                    </tr>
                    <tr>
                      <td className={td_title}>발행일</td>
                      <td className={td_body}>{data.pubDate}</td>
                    </tr>
                    <tr>
                      <td className={td_title}>ISBN</td>
                      <td className={td_body}>{data.isbn}</td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>

                <div className="text-xs mt-2">
                  최종 갱신일: {printTime(data.updateTime, true)} <br />
                  최초 등록일: {printTime(data.createTime, true)}
                </div>
              </div>
            </div>
            <div className="flex mb-12">
              {!!location?.state?.location && (
                <button
                  className="flex hover:bg-gray-200 p-2 text-gray-700  dark:hover:bg-gray-700 dark:hover:text-gray-200"
                  // onClick={() => {
                  //   navigate(location.state.location, {
                  //     state: location.state,
                  //   });
                  // }}
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon className="mt-[0.2rem] h-4 items-center justify-center " />
                  <span className="text-sm items-center justify-center font-semibold">
                    뒤로 가기
                  </span>
                </button>
              )}
              <div className="items-center flex ml-auto">
                {/* <div className="text-sm mr-4">장바구니에 추가 </div> */}
                <button
                  onClick={() => addCart(`점자`)}
                  className="ml-2 py-2 px-2 rounded-md bg-sky-700/20 text-sm md:text-md"
                >
                  점자 자료 신청
                </button>
                <button
                  onClick={() => addCart(`확대`)}
                  className="ml-2 py-2 px-2 rounded-md bg-green-700/20 text-sm md:text-md"
                >
                  확대 자료 신청
                </button>
                <button
                  onClick={() => addCart(`음성`)}
                  className="ml-2 py-2 px-2 rounded-md bg-yellow-700/20 text-sm md:text-md"
                >
                  음성 자료 신청
                </button>
              </div>
            </div>
          </>
        ) : (
          <div>존재하지 않는 자료입니다.</div>
        )}
      </div>
    </>
  );
}

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Auth from "./pages/Auth";
import NotFound from "./pages/NotFound";
import BookList from "./pages/BookList";
import HomeLayout from "./components/HomeLayout";
import RequireAuth from "./components/RequireAuth";
import BookItemCreate from "./pages/BookItemCreate";
import CameraSearch from "./pages/CameraSearch";
import NlSearch from "./pages/NlSearch";
import BookItem from "./pages/BookItem";

import BookItemEdit from "./pages/BookItemEdit";
import RequestList from "./pages/admin/RequestList";
import RequestRegister from "./pages/admin/RequestRegister";
import RequestItemEdit from "./pages/admin/RequestItemEdit.js";
import ProduceList from "./pages/admin/ProduceList";
import ProduceItemEdit from "./pages/admin/ProduceItemEdit.js";
import ProduceItemRequest from "./pages/admin/ProduceItemRequest.js";
import CartList from "./pages/admin/CartList.js";
import CartItem from "./pages/admin/CartItem.js";
import Search from "./pages/search/SearchMain.js";
import AdvancedSearch from "./pages/search/AdvancedSearchMain";
import UserCartList from "./pages/cart/CartList.js";
import UserCartItem from "./pages/cart/CartItem.js";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <HomeLayout>
              <Search />
            </HomeLayout>
          }
        />
        <Route path="/auth" element={<Auth />} />

        <Route
          path="/search/advanced"
          element={
            <HomeLayout>
              <AdvancedSearch />
            </HomeLayout>
          }
        />

        <Route
          path="/request/list"
          element={
            <HomeLayout>
              <RequireAuth>
                <RequestList />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/request/item/:id"
          element={
            <HomeLayout>
              <RequireAuth>
                <RequestItemEdit />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/request/register"
          element={
            <HomeLayout>
              <RequireAuth>
                <RequestRegister />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/admin/cart"
          element={
            <HomeLayout>
              <RequireAuth>
                <CartList />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/cart"
          element={
            <HomeLayout>
              <RequireAuth>
                <UserCartList />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/cart/item/:id"
          element={
            <HomeLayout>
              <RequireAuth>
                <UserCartItem />
              </RequireAuth>
            </HomeLayout>
          }
        />

        <Route
          path="/admin/cart/item/:id"
          element={
            <HomeLayout>
              <RequireAuth>
                <CartItem />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/produce/list"
          element={
            <HomeLayout>
              <RequireAuth>
                <ProduceList />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/produce/item/:id"
          element={
            <HomeLayout>
              <RequireAuth>
                <ProduceItemEdit />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/produce/item/:id/request"
          element={
            <HomeLayout>
              <RequireAuth>
                <ProduceItemRequest />
              </RequireAuth>
            </HomeLayout>
          }
        />
        {/* <Route
          path="/kotrysearch"
          element={
            <HomeLayout>
              <RequireAuth>
                <KotrySearch />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/opensearch"
          element={
            <HomeLayout>
              <RequireAuth>
                <KotryOpenSearch />
              </RequireAuth>
            </HomeLayout>
          }
        /> */}
        <Route
          path="/nlsearch"
          element={
            <HomeLayout>
              <RequireAuth>
                <NlSearch />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route path="/camera" element={<CameraSearch />} />
        {/* <Route path="/barcode" element={<BarcodeSearch />} />
        <Route path="/barcode2" element={<BarcodeScanner />} /> */}

        <Route
          path="/item/:id"
          element={
            <RequireAuth>
              <HomeLayout>
                <BookItem />
              </HomeLayout>
            </RequireAuth>
          }
        />

        <Route
          path="/admin/list"
          element={
            <HomeLayout>
              <RequireAuth>
                <BookList />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/admin/item/:id"
          element={
            <HomeLayout>
              <RequireAuth>
                <BookItemEdit />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/item/create"
          element={
            <HomeLayout>
              <RequireAuth>
                <BookItemCreate />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;

import qs from "query-string";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import ListPagination from "../../components/ListPagination";
import api from "../../lib/api";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";

export default function CartList() {
  // /api/userCartBook/list
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const location = useLocation();
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`CartItem`, page],
    queryFn: () =>
      axios
        .post(`/api/userCartBook/list`, qs.stringify({ page }), {
          headers: {
            Authorization: "bearer " + api.getToken(),
          },
        })
        .then((res) => {
          return res.data.data;
        }),
  });

  // useEffect(() => {
  //   refetch();
  // }, [page]);

  const removeItem = (id) => {
    axios
      .post(
        `/api/userCartBook/remove/${id}`,
        {},
        {
          headers: {
            Authorization: "bearer " + api.getToken(),
          },
        }
      )
      .then((res) => refetch())
      .catch((err) => console.log(err));
  };
  const tdhead = "text-xs w-20 pb-1 px-3 text-right text-gray-800";
  const tdbody = "text-sm pb-1 px-2";
  const linktoItem = (id) => {
    navigate(`/cart/item/${id}`, {
      state: { location: location.pathname },
    });
  };
  const ItemList = data?.items?.map((item, index) => {
    const type = item.prodType?.split(" ").map((item, index) => (
      <span
        key={"type" + index}
        className={
          item === "점자"
            ? `block ml-1 py-1 px-3 text-sm font-normal rounded-md bg-sky-700/20 `
            : item === "음성"
            ? `block ml-1 py-1 px-3 text-sm font-normal rounded-md bg-green-700/20 `
            : `block ml-1 py-1 px-3 text-sm font-normal rounded-md bg-yellow-700/20 `
        }
      >
        {item}
      </span>
    ));
    return (
      <table
        key={item.id}
        className="bg-gray-100 hover:bg-sky-50 my-2  w-full rounded-md "
      >
        <tbody className="text-sm">
          <tr>
            <td
              rowSpan={8}
              onClick={() => linktoItem(item.id)}
              className=" bg-gray-200 p-3 text-center w-28 md:w-40 text-gray-300 cursor-pointer"
            >
              cover
            </td>
            <td className="text-sm w-20 p-3 text-right items-center">
              <span className="text-sm px-2 rounded-md bg-gray-500 text-white">
                {item.grade}
              </span>
            </td>
            <td className="p-1 px-2 font-bold ">
              <span className="mr-auto">{item.title}</span>
            </td>
            <td className="flex items-center w-20 p-2">{type}</td>
          </tr>

          <tr>
            <td className={tdhead}>발행사</td>
            <td className={tdbody}>{item.publisher}</td>
            <td
              rowSpan={7}
              className="w-20 p-2 cursor-pointer hover:bg-red-100 group rounded-md"
              onClick={() => {
                removeItem(item.id);
              }}
            >
              <span
                className="ml-auto text-xs text-red-500"
                aria-label="삭제"
                role="button"
              >
                <TrashIcon className="h-6 text-gray-600 mx-auto group-hover:text-red-500" />
              </span>
            </td>
          </tr>
          <tr>
            <td className={tdhead}>저자</td>
            <td className={tdbody}>{item.creator}</td>
          </tr>
          <tr>
            <td className={tdhead}>발행일</td>
            <td className={tdbody}>{item.pubDate}</td>
          </tr>
          <tr>
            <td className={tdhead}>ISBN</td>
            <td className={tdbody}>{item.isbn}</td>
          </tr>
          <tr>
            <td className={tdhead}>교육과정</td>
            <td className={tdbody}>{item.curriculum}</td>
          </tr>
          <tr>
            <td className={tdhead}>국검인</td>
            <td className={tdbody}>{item.permission}</td>
          </tr>
          <tr>
            <td className={tdhead}>교과용도</td>
            <td className={tdbody}>{item.purpose}</td>
          </tr>
        </tbody>
      </table>
    );
  });

  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto m-4">
        <div className="font-bold text-2xl my-8">장바구니</div>
        <div className="overflow-x-auto xl:grid-cols-2 xl:grid xl:gap-4">
          {ItemList}
        </div>
        <ListPagination data={data} setPage={setPage} />
      </div>
    </>
  );
}

import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../lib/AuthProvider";
import { login_address, server_address } from "../lib/api_address";

export default function useLogin() {
  const [popup, setPopup] = useState();
  const { login, setUser } = useContext(AuthContext);
  const handleLogin = () => {
    var addr;
    if (window.location.href.split("/")[2] === `localhost:3000`) {
      addr = `${login_address}/?call=login&client_id=senseworldclient&response_type=code&redirect_uri=http://localhost:3000/auth`;
    } else {
      addr = `${login_address}/?call=login&client_id=senseworldclient&response_type=code&redirect_uri=${server_address}/auth`;
    }

    let width = 400;
    let height = 600;
    let top = (window.innerHeight - height) / 2 + window.screenY;
    let left = (window.innerWidth - width) / 2 + window.screenX;

    let spec = "status=no, menubar=no, toolbar=no, resizable=no";
    spec += ", width=" + width + ", height=" + height;
    spec += ", top=" + top + ", left=" + left;

    const popupWin = window.open(addr, `_blank`, spec);
    setPopup(popupWin);
  };

  useEffect(() => {
    if (!popup) {
      return;
    }
    const oAuthCodeListener = (e) => {
      // 동일한 Origin 의 이벤트만 처리하도록 제한
      if (e.origin !== window.location.origin) {
        console.log(`origin error`);
        return;
      }
      const { code } = e.data;

      if (code) {
        login(code)
          .then(() => {
            setUser();
          })
          .then(() => {
            popup?.close();
            setPopup(null);
          });
      }
    };

    window.addEventListener("message", oAuthCodeListener, false);

    return () => {
      window.removeEventListener("message", oAuthCodeListener);
      popup?.close();
      setPopup(null);
    };
  }, [popup]);

  return { handleLogin, setPopup };
}

var login_address;
var api_address;
var server_address;

// if (window.location.href.split(`/`)[2] === `contents.xvtech.com`) {
//   login_address = "https://dlgs.senwd.co.kr";
//   api_address = window.location.href.split(`/`)[2];
//   server_address = window.location.href.split(`/`)[2];
// } else {
//   login_address = "https://dlgs.senwd.co.kr";
//   api_address = "https://dapi.itlo.org";
//   server_address = "https://dlib.itlo.org";
// }
if (window.location.href.split(`/`)[2] === `localhost:3000`) {
  login_address = "https://dlgs.senwd.co.kr";
  api_address = `https://dcontents.xvtech.com/`;
  server_address = "https://" + window.location.href.split(`/`)[2];
} else {
  login_address = "https://dlgs.senwd.co.kr";
  api_address = "https://" + window.location.href.split(`/`)[2];
  server_address = "https://" + window.location.href.split(`/`)[2];
}

export { login_address, api_address, server_address };
